// Polyfills
import 'promise-polyfill/src/polyfill';

// NPM packages
import lozad                                              from 'lozad';
import { disableBodyScroll, enableBodyScroll }            from 'body-scroll-lock';
import Swiper, { Navigation, Pagination, Autoplay, Lazy } from 'swiper';
import SlideUpDown                                        from 'vue-slide-up-down';
/*import mixitup                                            from 'mixitup';
import mixitupMultifilter                                 from "./mixitup-multifilter.min.js"
import mixitupPagination                                  from "./mixitup-pagination.min.js"*/

Swiper.use([Navigation, Pagination, Autoplay, Lazy]);

// Lozad
window.lozadObserver = lozad('.lozad', {
    loaded: el => {
        const cropPos = el.getAttribute('data-crop-pos');
        if (cropPos) {
            const img = el.getElementsByTagName('img');
            if (img) img[0].style.objectPosition = cropPos;
        }
        el.classList.add('loaded');
    }
});

// Import our CSS
import '../css/app.pcss';

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const { default: mixitup } = await import( /* webpackChunkName: "mixitup" */ 'mixitup');
    const { default: mixitupMultifilter } = await import( /* webpackChunkName: "mixitup-multifilter" */ './mixitup-multifilter.min.js');
    const { default: mixitupPagination } = await import( /* webpackChunkName: "mixitup-pagination" */ './mixitup-pagination.min.js');
    const { default: AccordionGroup } = await import( /* webpackChunkName: "accordion-group" */ './vue/accordion/AccordionGroup.vue');
    const { default: AccordionItem } = await import( /* webpackChunkName: "accordion-group" */ './vue/accordion/AccordionItem.vue');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
    
    Vue.use(VueCookies);
    
    Vue.component('slide-up-down', SlideUpDown);
    Vue.component('accordion-group', AccordionGroup);
    Vue.component('accordion-item', AccordionItem);
    
    mixitup.use(mixitupMultifilter);
    mixitup.use(mixitupPagination);
    
    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        
        data: {
            // Cookie consent
            showStatusBar: true,
            
            // Navigation
            activeSegments: [],
            activeNavElementId: null,
            mobileNavIsOpen: false,
            lastScrollPos: 0,
            hideNav: false,
            fillNav: false,
            mobileNavActiveItemId: '',
            mobileNavActiveItemClickCount: 0,
            
            // Blocks
            activeQuickLinkId: '',
            popupId: '',
            
            // Mix-it-up
            noResultsFound: false
        },
        
        created() {
            window.addEventListener('scroll', this.onWindowScroll);
        },
        
        mounted() {
            window.lozadObserver.observe();
            
            // Set active navigation highlight
            if (window.activeNavElementId) this.activeNavElementId = window.activeNavElementId;
            const strSegments = window.location.pathname;
            this.activeSegments = strSegments.split('/').slice(1); // First segment is empty
            
            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }
            
            // Init pages
            this.initHomePage();
            
            // Init blocks
            this.initBlockFeaturedEntries();
            this.initVideo();
            
            // Mix-it-up
            this.initMixItUp();
        },
        
        beforeDestroy() {
            window.removeEventListener('scroll', this.onWindowScroll);
        },
        
        methods: {
            onWindowScroll() {
                const isScrollingDown = window.scrollY > this.lastScrollPos;
                const navigationHeight = this.$refs.header.clientHeight || 0;
                
                if (!this.mobileNavIsOpen) {
                    this.hideNav = isScrollingDown && (window.scrollY > navigationHeight);
                }
                
                this.fillNav = window.scrollY > 0;
                this.lastScrollPos = window.scrollY;
            },
            
            closeStatusBar(slug) {
                VueCookies.set(`status-${ slug }`, 'hidden', '7d');
                this.showStatusBar = false;
            },
            
            toggleMobileNav() {
                this.mobileNavIsOpen = !this.mobileNavIsOpen;
                this.mobileNavIsOpen ? disableBodyScroll(this.$refs.navMobile) : enableBodyScroll(this.$refs.navMobile);
            },
            
            toggleMobileNavAccordion(id) {
                this.mobileNavActiveItemId = this.mobileNavActiveItemId === id ? '' : id;
            },
            
            mainMenuLinkClicked(url) {
                // Check if it's a touch device, if so, wait until user clicks twice to go to main page
                if ('ontouchstart' in window || navigator.MaxTouchPoints > 0) {
                    this.mobileNavActiveItemClickCount++;
                    if (this.mobileNavActiveItemClickCount === 2) {
                        this.mobileNavActiveItemClickCount = 0;
                        window.location = url;
                    }
                } else {
                    window.location = url;
                }
            },
            
            initHomePage() {
                const homeHeroSwiperEl = document.getElementById('home-hero');
                if (homeHeroSwiperEl) {
                    const imageCount = homeHeroSwiperEl.dataset.count;
                    
                    new Swiper('#home-hero', {
                        // Optional parameters
                        loop: imageCount > 1,
                        speed: 2000,
                        watchOverflow: true,
                        autoplay: {
                            delay: 5000,
                        },
                        
                        // If we need pagination
                        pagination: {
                            el: '.swiper-pagination',
                            type: 'bullets',
                            bulletClass: 'pagination-bullet',
                            renderBullet: (index, className) => `<div class="${ className }"></div>`
                        }
                    });
                }
            },
    
            initVideo() {
                const blockEl = Array.from(document.querySelectorAll(".video"));
  
                blockEl.forEach(block => {
                    const videoEl = block.querySelector(".video__video video");
                    const overlayEl = block.querySelector('.video__overlay');
                    
                    // Remove controls by default
                    videoEl.removeAttribute("controls");
                    
                    // If our video is not playing by default
                    // we add a click event that triggers once to play the video.
                    if (videoEl.paused) {
                        overlayEl.addEventListener("click", (e) => {
                                e.preventDefault();
                                videoEl.play();
                            },
                            { once: true });
                    }
    
                    // Set video is playing state
                    videoEl.addEventListener("play", () => {
                        // Keeps track if the video has been played before or not (e.g. to hide the "play" icon after being initially played)
                        block.classList.add("video--has-played");
                        // Class added to the parent block so we know a video is currently playing
                        block.classList.add("video--playing");
                        // Add our default video controls
                        videoEl.setAttribute("controls", "true");
                    });
    
                    // Remove video is playing state
                    videoEl.addEventListener("pause", () => {
                        // Display that the video is not playing anymore
                        block.classList.remove("video--playing");
                    });
                });
 
            },
    
            initBlockFeaturedEntries() {
                const blockEl = document.querySelectorAll('.block-featured-entries');
                for (const block of blockEl) {
                    let swipeEl = (block.getElementsByClassName('swiper-container').length) ? block.getElementsByClassName('swiper-container')[0] : false;
                    if (swipeEl) {
                        const btnNext = (block.getElementsByClassName('swiper-next').length) ? block.getElementsByClassName('swiper-next')[0] : false;
                        const btnPrev = (block.getElementsByClassName('swiper-prev').length) ? block.getElementsByClassName('swiper-prev')[0] : false;
                        
                        new Swiper(swipeEl, {
                            preloadImages: false,
                            lazy: true,
                            slidesPerView: 1.3,
                            slidesOffsetAfter: 0, // 1.5rem
                            speed: 1000,
                            grabCursor: true,
                            watchOverflow: true,
                            navigation: {
                                nextEl: btnNext,
                                prevEl: btnPrev,
                            },
                            pagination: {
                                el: '.swiper-pagination',
                                type: 'fraction',
                                formatFractionCurrent: number => number < 10 ? '0' + number : number,
                                formatFractionTotal: number => number < 10 ? '0' + number : number,
                                renderFraction: (currentClass, totalClass) => `<span class="${ currentClass } numbers"></span><span class="mx-1">/</span><span class="${ totalClass } numbers"></span>`
                            },
                            breakpoints: {
                                // when window width is >= 768px
                                768: {
                                    slidesPerView: 2.8
                                }
                            }
                        });
                    }
                }
            },
            
            initMixItUp() {
                // For now we only support a single container on a page
                const topicsFilter = this.$refs.topicsFilter;
                const industriesFilter = this.$refs.industriesFilter;
                const container = document.querySelector('.mix-it-up-container');
                
                if (container) {
                    // Init MixItUp
                    const mixItUp = mixitup(container, {
                        animation: {
                            effects: 'fade translateY(20%)',
                            easing: 'ease-in-out',
                            duration: 400
                        },
                        multifilter: {
                            enable: true
                        },
                        pagination: {
                            limit: 12,
                            hidePageListIfSinglePage: true,
                            maintainActivePage: false
                        },
                        render: {
                            pager: data => {
                                if (data.isTruncationMarker) return `<span class="${ data.classNames }">&hellip;</span>`;
                                if (data.isNext) return `
                                    <button type="button" class="${ data.classNames }" data-page="next">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.4 19.3" width="22" height="19" xml:space="preserve" fill="none" stroke="#F1F1FB">
                                            <g stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M1.7 9.6h18M12.7 18.1l8-8.5-8-8.5"/>
                                            </g>
                                        </svg>
                                    </button>`;
                                if (data.isPrev) return `
                                    <button type="button" class="${ data.classNames }" data-page="prev">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.4 19.3" width="22" height="19" xml:space="preserve" fill="none" stroke="#F1F1FB">
                                            <g stroke-linecap="round" stroke-linejoin="round">
                                                <path d="M1.7 9.6h18M12.7 18.1l8-8.5-8-8.5"/>
                                            </g>
                                        </svg>
                                    </button>`;
                                return `<button type="button" class="${ data.classNames } numbers" data-page="${ data.pageNumber }">${ data.pageNumber < 10 ? 0 : "" }${ data.pageNumber }</button>`;
                            }
                        },
                        callbacks: {
                            onMixEnd: state => {
                                this.noResultsFound = state.hasFailed;
                            },
                            onPaginateEnd: () => {
                                window.scroll({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth'
                                });
                            }
                        }
                    });
                    
                    // Apply filters
                    let filterString = '.mix';
                    if (topicsFilter) {
                        topicsFilter.addEventListener('change', () => {
                            filterString = filterString + "," + topicsFilter.value
                        })
                    }
                    
                    if (industriesFilter) {
                        industriesFilter.addEventListener('change', () => {
                            filterString = filterString + "," + industriesFilter.value
                        })
                    }
                    
                    mixItUp.filter(filterString);
                }
            },
            
            toggleTeamPopUp(id) {
                this.popupId = this.popupId === id ? '' : id;
                const popUp = document.getElementById('pop-up-' + id);
                
                if(popUp) {
                    this.popupId !== '' ? disableBodyScroll(popUp) : enableBodyScroll(popUp);
                }
            }
        },
        
        computed: {}
    });
    
    return vm;
};

// Execute async function
main().then((vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
